<template>
  <b-card title="Create User">
    <loading :active="isLoading" loader="bars" color="#7367f0" :is-full-page="fullPage" />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Name" label-for="name">
              <validation-provider #default="{ errors }" name="Name" rules="required">
                <v-select v-model="people" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  :options="peopleOptions" @search="onSearch" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Role" label-for="role">
              <validation-provider #default="{ errors }" name="Role" rules="required">
                <v-select v-model="roles" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  :options="roleOptions" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="iFrame URL" label-for="iframe_url">
              <validation-provider #default="{ errors }" name="iFrame URL" rules="required">
                <b-form-input id="iframe_url" v-model="iframeUrl" :state="errors.length > 0 ? false:null"
                  placeholder="iFrame URL" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Second iFrame URL" label-for="second_iframe_url">
              <b-form-input id="second_iframe_url" v-model="secondIframeUrl" placeholder="Second iFrame URL" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Third iFrame URL" label-for="third_iframe_url">
              <b-form-input id="third_iframe_url" v-model="thirdIframeUrl" placeholder="Third iFrame URL" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mr-1" type="submit" @click.prevent="validationForm">
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              :to="{ name: 'users' }">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
// import CryptoJS from 'crypto-js'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,

    vSelect,
    Loading,
  },
  directives: {
      Ripple,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      people: [],
      peopleOptions: [],
      email: '',
      iframeUrl: '',
      secondIframeUrl: null,
      thirdIframeUrl: null,
      roles: {},
      roleOptions: [],
      showMinistries: true,
      required,
    }
  },
  async mounted() {
    // Get the roles data
    const roles = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/roles?token=${process.env.VUE_APP_API_TOKEN}`)

    // Hide loading
    this.isLoading = false

    const allRoles = []
    roles.data.result.forEach(role => {
        const roleData = {
            id: role.id,
            name: role.name,
        }

        allRoles.push(roleData)
    })
    this.roleOptions = allRoles
  },
  methods: {
    onSearch(search, loading) {
        if (search.length) {
            loading(true)
            this.search(loading, search, this)
        }
    },
    search: _.debounce((loading, search, vm) => {
        vm.$http.get(
            `${process.env.VUE_APP_API_BASE_URL}/api/v1/people?token=${process.env.VUE_APP_API_TOKEN}&q=${search}`,
        ).then(res => {
            vm.peopleOptions = res.data.result
            loading(false)
        })
    }, 350),
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }
      
      // Submit
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // Show loading
          this.isLoading = true

          // Set the data
          const data = {
            people_id: this.people.id,
            iframe_url: this.iframeUrl,
            roles_id: this.roles.id,
          }
          if (this.secondIframeUrl) {
            data.second_iframe_url = this.secondIframeUrl
          }
          if (this.thirdIframeUrl) {
            data.third_iframe_url = this.thirdIframeUrl
          }

          // Check if user has access
          if (!this.$can('add', 'user')) {
            this.isLoading = false
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Update user data first
          this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/user?token=${process.env.VUE_APP_API_TOKEN}`, data).then(() => {
            // Redirect to status lists
            this.isLoading = false
              this.$router.replace({ name: 'users' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Data has been saved successfully.',
                    },
                  })
                })
              })
        }
      })
    },
  },
}
</script>